<template>
  <div style="padding: 20px">
    <el-form :inline="true" :model="queryForm" class="demo-form-inline">
      <el-form-item label="艺术家昵称">
        <el-input
          v-model="queryForm.artist_nickname"
          placeholder="请输入"
          @keyup.enter.native="onQuery"
        />
      </el-form-item>
      <el-form-item label="艺术家UCID">
        <el-input
          v-model="queryForm.artist_ucid"
          placeholder="请输入"
          @keyup.enter.native="onQuery"
        />
      </el-form-item>
      <el-form-item label="nft名称">
        <el-input
          v-model="queryForm.work_name"
          placeholder="请输入"
          @keyup.enter.native="onQuery"
        />
      </el-form-item>
      <el-form-item label="核销人姓名">
        <el-input
          v-model="queryForm.receiver_name"
          placeholder="请输入"
          @keyup.enter.native="onQuery"
        />
      </el-form-item>
      <el-form-item label="核销人昵称">
        <el-input
          v-model="queryForm.write_off_nickname"
          placeholder="请输入"
          @keyup.enter.native="onQuery"
        />
      </el-form-item>
      <el-form-item label="核销人UCID">
        <el-input
          v-model="queryForm.write_off_ucid"
          placeholder="请输入"
          @keyup.enter.native="onQuery"
        />
      </el-form-item>
      <el-form-item label="核销状态">
        <el-select v-model="queryForm.write_off_status" placeholder="请选择">
          <el-option
            v-for="item in statusList"
            :key="item.id"
            :label="item.title_cn"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onQuery">查询</el-button>
        <el-button type="info" @click="onClear">重置</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="list" border stripe row-key="write_off_id">
      <el-table-column label="nft名称" prop="work_name">
        <template slot-scope="scope">
          <span>{{ `${scope.row.work_name}${scope.row.index}` }}</span>
        </template>
      </el-table-column>
      <el-table-column label="艺术家信息" prop="creator.name">
        <template slot-scope="scope">
          <span>{{ `${scope.row.creator.name}(${scope.row.creator.id})` }}</span>
        </template>
      </el-table-column>
      <el-table-column label="核销人信息" prop="write_off_nickname">
        <template slot-scope="scope">
          <span>{{ `${scope.row.write_off_nickname}(${scope.row.write_off_ucid})` }}</span>
        </template>
      </el-table-column>
      <el-table-column label="核销状态" prop="write_off_status">
        <template slot-scope="scope">
          <span>{{ scope.row.write_off_status | statusFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column label="姓名" prop="receiver_name" />
      <el-table-column label="电话" prop="mobile" />
      <el-table-column label="物流信息" prop="express_order_no">
        <template slot-scope="scope">
          <div>{{ scope.row.express_order_no | formatvalue }}</div>
        </template>
      </el-table-column>
      <el-table-column label="核销申请时间" prop="wirte_off_created_at">
        <template slot-scope="scope">
          <div>{{ scope.row.write_off_created_at | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope" prop="write_off_id">
          <el-button
            size="mini"
            type="primary"
            @click="showInfo(scope.row)"
            style="margin-right: 10px"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="核销详情"
      :visible.sync="infoVisible"
      width="600px"
      @close="closeInfoDialog"
      destroy-on-close
    >
      <div v-if="infoVisible">
        <div class="info">
          <div class="info-lable">nft名称</div>
          <div>{{ orderInfo.work_name }}</div>
        </div>
        <div class="info">
          <div class="info-lable">作品编号</div>
          <div>{{ orderInfo.index }}</div>
        </div>
        <div class="info">
          <div class="info-lable">核销说明</div>
          <div>{{ orderInfo.write_off_desc | formatvalue }}</div>
        </div>
        <el-divider></el-divider>
        <div class="info">
          <div class="info-lable">核销人昵称</div>
          <div>{{ orderInfo.write_off_nickname }}</div>
        </div>
        <div class="info">
          <div class="info-lable">核销人姓名</div>
          <CopyText :text="orderInfo.receiver_name" />
        </div>
        <div class="info">
          <div class="info-lable">核销人电话</div>
          <CopyText :text="orderInfo.mobile" />
        </div>
        <div class="info">
          <div class="info-lable">核销人地址</div>
          <CopyText :text="orderInfo.address" />
        </div>
        <div class="info">
          <div class="info-lable">核销备注</div>
          <div>{{ orderInfo.remark | formatvalue }}</div>
        </div>
        <div class="info">
          <div class="info-lable">核销申请时间</div>
          <div>{{ orderInfo.write_off_created_at | formatDate }}</div>
        </div>
        <el-divider></el-divider>
        <div class="info">
          <div class="info-lable">核销状态</div>
          <div v-if="orderInfo.write_off_status">
            {{ +orderInfo.write_off_status | statusFilter }}
          </div>
          <div v-else>{{ orderInfo.write_off_status | formatvalue }}</div>
        </div>
        <div class="info">
          <div class="info-lable">物流信息</div>
          <div>{{ orderInfo.express_order_no | formatvalue }}</div>
        </div>
        <div class="info">
          <div class="info-lable">确认时间</div>
          <div v-if="orderInfo.write_off_confirm_at">
            {{ orderInfo.write_off_confirm_at | formatDate }}
          </div>
          <div v-else>{{ orderInfo.write_off_confirm_at | formatvalue }}</div>
        </div>
        <div class="info">
          <div class="info-lable">其他信息</div>
          <div>{{ orderInfo.write_off_remark | formatvalue }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getVerificationList } from "@/api/nft.js";
import dayjs from "dayjs";
import CopyText from "@/components/CopyText.vue";

export default {
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      list: [],
      statusList: [
        { id: 0, title_cn: "全部" },
        { id: 1, title_cn: "确认中" },
        { id: 2, title_cn: "已核销" },
      ],
      orderInfo: {},
      infoVisible: false,
      queryForm: {},
    };
  },
  components: {
    CopyText,
  },
  filters: {
    formatvalue: function(value) {
      return value ? value : "--";
    },
    formatDate: function(value) {
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
    statusFilter: function(value) {
      // 0-未核销1-待确认2-已核销
      const statusMap = {
        1: "确认中",
        2: "已核销",
      };
      return statusMap[value];
    },
  },
  mounted() {
    //   const search = new URLSearchParams(window.location.search);
    //   const badge_code = search.get("badge_code");
    //   if (badge_code) {
    //     this.badge_code = badge_code;
    //   }
    //   const buyer_id = search.get("buyer_id");
    //   if (buyer_id) {
    //     this.buyer_id = buyer_id;
    //   }
    //   const seller_id = search.get("seller_id");
    //   if (seller_id) {
    //     this.seller_id = seller_id;
    //   }
    this.fetchVerificationOrders();
  },
  methods: {
    getSearchParams() {
      const params = {
        ...this.queryForm,
        page: this.page,
        page_size: this.pageSize,
      };
      return params;
    },
    async fetchVerificationOrders() {
      try {
        const params = this.getSearchParams();
        const { data } = await getVerificationList(params);
        const { total = 0, items = [] } = data?.data;
        this.total = total;
        this.list = [...items];
      } catch (error) {
        console.log(error);
      }
    },
    showInfo(row) {
      this.orderInfo = row;
      this.infoVisible = true;
    },
    closeInfoDialog() {
      this.infoVisible = false;
      this.orderInfo = {};
    },

    onQuery() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchVerificationOrders();
    },
    onClear() {
      this.page = 1;
      this.pageSize = 20;
      this.queryForm = {};
      this.fetchVerificationOrders();
    },

    handleCurrentChange(page) {
      this.page = page;
      this.fetchVerificationOrders();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchVerificationOrders();
    },
    handleStatusChange() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchVerificationOrders();
    },

    handleCopy(text) {
      this.$copyText(text).then(() => {
        this.$message.success("复制成功");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  display: flex;
  align-items: center;
  .info-lable {
    min-width: 120px;
  }
}
</style>
