<template>
  <el-row type="flex" align="middle" justify="center">
    <span style="margin-right: 8px">{{ text }}</span>
    <el-button circle size="mini" icon="el-icon-copy-document" @click="handleCopy(text)" />
  </el-row>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleCopy(text) {
      this.$copyText(text).then(() => {
        this.$message.success("复制成功");
      });
    },
  },
};
</script>
